import React from "react"
import { Link } from "gatsby"
import TestimonialSection from "../components/testimonials-section"
import Collapsible from 'react-collapsible';
import Layout from "../components/layout"
import Hero from "../components/hero"    
import Cta from "../components/cta"
import Seo from "../components/seo"
import ChevronDown from "../components/svg/chevronDown"
import ArrowRight from "../components/svg/arrowRight"

const PythonPage = () => (
    <Layout>
        <Seo 
            title="Python developers Sydney & Canberra | Sydney Python development agency" 
            description="Experienced Python developers in Sydney and Canberra. We develop web applications, and machine learning solutions, using Python."
            pathname="/python-developer/"
        />

        <Hero
            h1="Python developers"  
            h2="We're highly experienced Python developers in Sydney and Canberra"
        />
        
        <section className="bg-purple with-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="below-hero"
                        data-sal="slide-right" 
                        data-sal-easing="ease-in-out"
                        data-sal-duration="400"
                    >
                        <div className="below-hero-widget">
                            <span className="widget-heading">15+</span>
                            <p>We have 15 years experience using Python for backend development and data science.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">50+</span>
                            <p>We've shipped more than 50 Python applications.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">Local</span>
                            <p>All of our Python development work is done onshore in Australia.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3
                            >Web &amp; app development</h3>
                        </div>

                        <div className="col-67 long-text"
                            data-sal="slide-up" 
                            data-sal-easing="ease-in-out"
                            data-sal-duration="400"
                        >
                            <p>
                                Since Launch Lab opened its doors in 2014 we have developed more than 40 startup applications, content management systems, backends for mobile apps, chatbots and data science / machine learning solutions using Python.
                            </p>
                            <p>
                                We continue to offer <Link to="/django-developer-sydney-canberra/">Django development</Link> however we now complete most of our application development leveraging <strong>AWS CDK with Python</strong>.   
                            </p>
                            <p>
                                We also use our Python skills for <Link to="/machine-learning-developers-sydney/">machine learning development</Link>.   
                            </p>
                            <p>
                                If you're looking for a Python agency, we'd love to discuss how we can help.  
                            </p>
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                        Contact us
                                        <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad bg-black">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h5 className="title">
                                FAQs
                            </h5>
                        </div>
                        <div className="col-67">
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Are you Australian Python developers? <ChevronDown />
                                </>
                                }
                            >
                                <p>Yes. All of our Python developers are highly experienced and based in Sydney and Canberra.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you only use Python or do you offer other tech services? <ChevronDown />
                                </>
                                }
                            >
                                <p>We offer a full stack web and application development service starting from web design and web development through to software development.</p>
                                <p>We use Python on the backend, React on the frontend and usually use AWS (serverless) for infrastructure.</p>
                                <p>View all of our <Link to="/services/"> development services</Link>.</p>
                            </Collapsible>
                        </div>
                    </div>
 
                </div>
            </div>
        </section>

        <TestimonialSection />

        <Cta 
            text="Get a Python web development quote from a highly experienced team of Australian Python developers"
        />
    </Layout>
)

export default PythonPage